<template>
  <div class="create-notification">
    <NotificationAppbar />
    <div class="ma-4">
      <NotificationForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Notification
 * ==================================================================================
 **/

import NotificationAppbar from '@/views/Home/Notifications/components/NotificationAppbar'
import NotificationForm from '@/views/Home/Notifications/components/NotificationForm'

export default {
  components: {
    NotificationAppbar,
    NotificationForm,
  },
}
</script>
<style lang="scss">
.create-notification {
  //
}
</style>
